<template>
  <div class="topmargin">
    <topNav :title="$route.query.name" :noborder="true"></topNav>
    <div class="choosebox">
      <ul>
        <li v-for="item in conditionsList" :key="item.key" class="clearfix">
          <p class="chooseName fl">{{ item.desc }}</p>
          <div class="fl">
            <a
              href="javaScript:"
              v-for="sort in item.values"
              :key="sort.code"
              @click="chooseSort(item.key, sort.code, sort.desc)"
              :class="{ blue: sortValue[item.key].value == sort.code }"
            >
              {{ sort.desc }}
            </a>
          </div>
        </li>
      </ul>
    </div>
    <!-- fixed-hint -->
    <div class="choosehint" @click="goTop()" v-show="showType">
      <template
        v-if="
          (sortValue.finish.value == -1) &
            (sortValue.words.value == -1) &
            (sortValue.free.value == -1)
        "
      >
        <a href="javaSvript:">全部</a>
        <img src="~img/home/Path7@2x.png" alt="" />
      </template>
      <template v-else>
        <a href="javaScript:">{{ sortValue.finish.desc }}</a>
        <a href="javaScript:"
          ><span class="circle"></span>{{ sortValue.words.desc }}</a
        >
        <a href="javaScript:"
          ><span class="circle"></span>{{ sortValue.free.desc }}</a
        >
        <img src="~img/home/Path7@2x.png" alt="" />
      </template>
    </div>
    <div class="ShelfRecommend normalBox threeShelf">
      <ul
        class="clearfix shelf1"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="loading"
        infinite-scroll-distance="200"
      >
        <li v-for="book in shelf" class="threeItem clearfix" :key="book.id">
          <router-link :to="'/book/' + book.id" class="fl">
            <img v-lazy="book.iconUrlSmall" alt="" />
          </router-link>
          <div class="fl rbox">
            <router-link :to="'/book/' + book.id" class="bookname">
              {{ book.name }}
            </router-link>
            <div class="bookintro">
              <p class="introCon">{{ book.introduce }}</p>
              <div class="clearfix">
                <router-link :to="'/book/' + book.id" class="fl authorName">{{
                  book.authorname
                }}</router-link>
                <div class="fr clearfix">
                  <router-link :to="'/book/' + book.id" class="tag fl">{{
                    book.sortName
                  }}</router-link>
                  <router-link :to="'/book/' + book.id" class="fl tag finish">{{
                    book.finished ? "完結" : "連載中"
                  }}</router-link>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div v-if="showMore" class="nonehint">
      <img src="~img/account/nonehint.png" alt="" />
    </div>
  </div>
</template>

<script>
import { sortAll, conditions } from "apiurl/search";
export default {
  data() {
    return {
      shelf: [],
      pageNum: 0,
      loading: false,
      hasNextPage: false,
      showMore: false,
      conditionsList: [],
      sortValue: {
        finish: { desc: "全部", value: -1 },
        words: { desc: "全部", value: -1 },
        free: { desc: "全部", value: -1 }
      },
      showType: false,
      scrollTop: 0
    };
  },
  created() {
    this.$nextTick(function() {
      this.getconditions();
    });
  },
  methods: {
    async loadMore() {
      if (!this.hasNextPage) {
        this.loading = false;
        this.pageNum++;
        let res = await sortAll({
          sort: this.$route.params.id,
          page: this.pageNum,
          size: 10,
          finish:
            this.sortValue.finish.value == -1
              ? ""
              : this.sortValue.finish.value == 0
              ? false
              : true,
          free:
            this.sortValue.free.value == -1
              ? ""
              : this.sortValue.free.value == 0
              ? false
              : true,
          words: this.sortValue.words.value
        });
        if (res.success) {
          res.data.content.forEach(item => {
            item.introduce = item.introduce.substring(0, 30) + "...";
          });
          this.hasNextPage = res.data.last;
          this.shelf = [...this.shelf, ...res.data.content];
          if (this.hasNextPage) {
            this.showMore = true;
            this.loading = true;
          } else {
            this.showMore = false;
            this.loading = false;
          }
        }
      } else {
        this.loading = true;
        this.showMore = true;
      }
    },
    // 獲取條件
    getconditions() {
      conditions().then(res => {
        if (res.success) {
          this.conditionsList = res.data;
        }
      });
    },
    // 爲了計算距離頂部的高度，當高度大於60顯示回頂部圖標，小於60則隱藏
    topShow() {
      const that = this;
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      that.scrollTop = scrollTop;
      if (that.scrollTop > 200) {
        that.showType = true;
      } else {
        that.showType = false;
      }
    },
    chooseSort(key, sort, sortName) {
      this.sortValue[key].value = sort;
      this.sortValue[key].desc = sortName;
      this.pageNum = 1;
      sortAll({
        sort: this.$route.params.id,
        page: this.pageNum,
        size: 10,
        finish:
          this.sortValue.finish.value == -1
            ? ""
            : this.sortValue.finish.value == 0
            ? false
            : true,
        free:
          this.sortValue.free.value == -1
            ? ""
            : this.sortValue.free.value == 0
            ? false
            : true,
        words: this.sortValue.words.value
      }).then(res => {
        if (res.success) {
          res.data.content.forEach(item => {
            item.introduce = item.introduce.substring(0, 30) + "...";
          });
          this.hasNextPage = res.data.last;
          this.shelf = res.data.content;
          if (this.hasNextPage) {
            this.showMore = true;
            this.loading = true;
          } else {
            this.showMore = false;
            this.loading = false;
          }
        }
      });
    },
    goTop() {
      const that = this;
      let timer = setInterval(() => {
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.topShow);
  },
  destroyed() {
    window.removeEventListener("scroll", this.topShow);
  }
};
</script>

<style lang="stylus" scoped>
.topmargin
    width 100vw
    overflow hidden
    margin-top 100px
.introCon
    padding-bottom 16px
    border-bottom 2px solid #E9ECF0
    line-height 33px
    margin-bottom 15px
.authorName
    font-size 22px
    color rgba(172, 175, 194, 1)
    line-height 30px
.nonehint
    margin 65px auto 30px
    width 209px
    img
        width 209px
.choosebox
    width:750px;
    background:rgba(255,255,255,1);
    padding 11px 6px 4px 26px
    box-sizing border-box
    box-shadow:0px 1px 16px 0px rgba(47,50,127,0.11);
    ul
        li
            min-height 45px
            margin-bottom 24px
            p
                width 75px
                line-height 37px
                color:rgba(153,153,153,1);
                letter-spacing:1px;
                font-size:26px;
            a
                color #454545
                font-size 26px
                padding 5px 10px
                &.blue
                    color #2188F2
                    background:rgba(227,239,251,1);
                    border-radius:4px;
.choosehint
    background:rgba(255,255,255,1);
    width 100%
    padding 11px 6px 26px 26px
    box-sizing border-box
    height 70px
    line-height 45px
    box-shadow:0px 1px 16px 0px rgba(47,50,127,0.11);
    position fixed
    top 99px
    text-align center
    img
        width 10px
        height 20px
        margin-left 30px
    a
        font-size 26px
        position relative
        color #555
    .circle
        display inline-block
        width:6px;
        height:6px;
        background:rgba(85,85,85,1);
        opacity:0.55;
        border-radius 50%
        margin 0 10px
        position relative
        top -5px
</style>
